

@import url('https://fonts.googleapis.com/css2?family=Instrument+Sans:ital,wght@0,400..700;1,400..700&display=swap');

@font-face {
  font-family: 'Cabinet Grotesk';
  src: url('/src/assets/fonts/CabinetGrotesk-Thin.otf') format('opentype');
  font-weight: 100; /* Thin */
  font-style: normal;
}

@font-face {
  font-family: 'Cabinet Grotesk';
  src: url('/src/assets/fonts/CabinetGrotesk-Extralight.otf') format('opentype');
  font-weight: 200; /* ExtraLight */
  font-style: normal;
}

@font-face {
  font-family: 'Cabinet Grotesk';
  src: url('/src/assets/fonts/CabinetGrotesk-Light.otf') format('opentype');
  font-weight: 300; /* Light */
  font-style: normal;
}

@font-face {
  font-family: 'Cabinet Grotesk';
  src: url('/src/assets/fonts/CabinetGrotesk-Regular.otf') format('opentype');
  font-weight: 400; /* Regular */
  font-style: normal;
}

@font-face {
  font-family: 'Cabinet Grotesk';
  src: url('/src/assets/fonts/CabinetGrotesk-Medium.otf') format('opentype');
  font-weight: 500; /* Medium */
  font-style: normal;
}

@font-face {
  font-family: 'Cabinet Grotesk';
  src: url('/src/assets/fonts/CabinetGrotesk-Bold.otf') format('opentype');
  font-weight: 700; /* Bold */
  font-style: normal;
}

@font-face {
  font-family: 'Cabinet Grotesk';
  src: url('/src/assets/fonts/CabinetGrotesk-Extrabold.otf') format('opentype');
  font-weight: 800; /* ExtraBold */
  font-style: normal;
}

@font-face {
  font-family: 'Cabinet Grotesk';
  src: url('/src/assets/fonts/CabinetGrotesk-Black.otf') format('opentype');
  font-weight: 900; /* Black */
  font-style: normal;
}






@tailwind base;
@tailwind components;
@tailwind utilities;
